import PropTypes from 'prop-types'
import React, { forwardRef, useContext, useEffect, useState } from 'react'
import '../../styles/index.scss'
import ContributionContext from '../../contexts/ContributionContext'
import RecordContext from '../../contexts/RecordContext'
import UserContext from '../../contexts/UserContext'
import { Alert } from '../Alert/Alert'
import { Button } from '../Button/Button'
import CommentGroup from '../description/contributions/comments/comment_group'
import CommentInput from '../description/contributions/comments/comment_input'
import TagGroup from '../description/contributions/tags/tag_group'
import TagInput from '../description/contributions/tags/tag_input'
import Transcription from '../description/contributions/transcriptions/Transcription'
import TranscriptionActions from '../description/contributions/transcriptions/transcription_actions'
import { Pill } from '../Pill/Pill'
import Tooltip from '../Tooltip/Tooltip'
import { ObjectContributionsLoader } from './object-contributions-loader'
import useUrlParams from '../../hooks/use-url-params'
import { Rnd } from 'react-rnd'
import { useLocation } from 'react-router-dom'
import { localTime, timeSince } from '../../helper-functions/date-functions'
import StaffPill from '../Pill/variants/StaffPill'
import './contribution_panel.scss'
import StaticPill from '../Pill/variants/StaticPill'
import { compareDates } from '../../helper-functions/sort-functions'
import AIChat from './aiextracted'

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #ddd',
  background: '#f0f0f0',
}

const ContributionPanel = forwardRef(
  (
    {
      contributions,
      averageConfidenceScore = 0,
      filteredObjects,
      mode,
      screenSize,
      ...props
    },
    ref
  ) => {
    // Get current params and param functions
    const { state } = useLocation()
    const { removeParams, updateParams, params } = useUrlParams(state)
    const objectPanel = mode ? mode : params['objectPanel']

    const { objectId } = useContext(RecordContext) || {}

    const {
      activeTranscriptions,
      comments: commentsArray,
      currentActiveTranscription,
      currentTranscription,
      currentTranscriptionIsLocked,
      loggedInUserIsEditing,
      lockedTranscriptions,
      tags,
      objectTagsLoading,
    } = useContext(ContributionContext) || {}

    const { loggedInUser } = useContext(UserContext) || {}

    const [loading, setLoading] = useState(true)
    const [isEditing, setIsEditing] = useState(false)

    const [contributionPanelHeight, setContributionPanelHeight] =
      useState(false)

    const [hasContribution, setHasContribution] = useState(false)
    const objectTags =
      tags?.length > 0
        ? tags.filter((tag) => tag.targetObjectId == objectId)
        : []
    const comments =
      commentsArray?.length > 0
        ? commentsArray.filter(
            (comment) =>
              comment.targetObjectId && comment.targetObjectId == objectId
          )
        : []
    const extractedText = filteredObjects[0].extractedText
    const otherExtractedText = filteredObjects[0].latestOET
    const digitalObjectInfo = filteredObjects[0]

    useEffect(() => {
      if (objectPanel !== 'transcription') return false
      if (currentActiveTranscription) return setLoading(false)
      return () => {
        setLoading(true)
      }
    }, [params, currentActiveTranscription])

    const handleSetHasContribution = () => {
      let hasCont = false
      switch (objectPanel) {
        case 'transcription':
          if (currentActiveTranscription?.contribution) hasCont = true
          break
        case 'tag':
          if (objectTags.length > 0) hasCont = true
          break
        case 'comment':
          if (comments.length > 0) hasCont = true
          break
        case 'aiextracted':
          if (extractedText || otherExtractedText) hasCont = false
          break
        default:
          break
      }
      setHasContribution(hasCont)
    }
    /**
     * TO DO: Fix this!
     * The loader only displays when transitioning from the transcription panel
     */
    useEffect(() => {
      handleSetHasContribution()
      const handler = setTimeout(() => {
        setLoading(false)
      }, 500)
      return () => {
        clearTimeout(handler)
      }
    }, [objectPanel])

    useEffect(() => {
      handleSetHasContribution()
    }, [currentTranscription, objectTags, comments, objectPanel])

    const transcriptionExists = activeTranscriptions?.contains(objectId)
    const transcriptionLocked = lockedTranscriptions?.contains(objectId)

    const panelContent = {
      transcription: {
        title: 'Transcription',
        emptyText:
          loggedInUser?.userId &&
          currentTranscriptionIsLocked &&
          !transcriptionExists
            ? 'Someone is currently transcribing this object. Check back later to access the latest version.'
            : 'No one has transcribed this yet.',
        note: 'Transcriptions help make records searchable and accessible for everyone.',
        action: 'Log in to transcribe',
      },
      tag: {
        title: 'Tags',
        emptyText: 'No one has tagged this yet.',
        note: 'Tags help give context to records and make them searchable.',
        action: 'Log in to tag',
      },
      comment: {
        title: 'Comments',
        emptyText: 'No one has commented on this yet.',
        note: 'Comments are a place to ask questions and provide answers.',
        action: 'Log in to comment',
      },
      extracted: {
        title: 'Extracted Text',
        emptyText: 'No Extractions Found',
        note: 'Extractions',
        action: 'Log in to see Extractions',
      },
      combo: {
        title: 'Extracted Text',
        emptyText: 'No Extractions Found',
        note: 'Extractions',
        action: 'Log in to see Extractions',
      },
      aiextracted: {
        title: 'AI Assistant',
        emptyText: 'Start a conversation with AI',
        note: 'Ask questions about documents and get AI-powered insights',
        action: 'Start chatting',
      },
    }

    const userIsTranscribing =
      loggedInUserIsEditing && objectPanel === 'transcription'

    const lastSaved =
      currentTranscription &&
      localTime(
        currentTranscription.updatedAt ||
          currentTranscription.createdAt ||
          currentTranscription.responseTimestamp
      )

    useEffect(() => {
      setTimeout(() => {
        setContributionPanelHeight(
          document.querySelector('.digital-objects')?.clientHeight - 50
        )
      }, 2000)
    }, [loading])

    // if (objectPanel == '\') {
    //   return <div>test</div>
    // }

    let leftPosition = -100
    if (mode === 'transcription') {
      leftPosition = -500
    }

    return objectPanel ? (
      <div className="wrap">
        {contributionPanelHeight > 0 && (
          <Rnd
            style={style}
            dragHandleClassName="dragme"
            default={{
              x: leftPosition,
              y: 0,
              background: 'red',
              width: 400,
              height: contributionPanelHeight,
            }}
          >
            <div className="wrapper">
              <div
                /**
                 * ACCESSIBILITY:
                 * When a tab is selected or active, its corresponding controlled tabpanel
                 * should have its aria-expanded attribute set to true and
                 * its hidden attribute set to false, otherwise the reverse.
                 */
                aria-expanded={objectPanel}
                aria-labelledby={`${objectPanel}-tab`}
                className={[
                  'resize both',
                  'handle',
                  'bg-base-lighter',
                  'grid-col-6',
                  'display-flex',
                  'flex-column',
                  'flex-grow-0',
                  'height-full',
                  'minw-0',
                ].join(' ')}
                data-testid={`nac-object-viewer--${objectPanel}-panel`}
                hidden={!objectPanel}
                id="contribution-panel"
                ref={ref}
                role="tabpanel"
              >
                <img
                  src="/images/misc/drag.png"
                  alt="drag"
                  style={{ height: 25, width: 25, margin: 5 }}
                  className="dragme"
                />
                {/**
                 * Contribution Panel Header
                 */}
                <div
                  className={[
                    'border-base-light',
                    'border-bottom-1px',
                    'display-flex',
                    'flex-column',
                    'flex-grow-0',
                    // 'grid-col',
                    'padding-x-2',
                    'padding-y-1',
                    'width-full',
                  ].join(' ')}
                >
                  <div
                    className={[
                      'display-flex',
                      'flex-align-center',
                      'flex-gap-xs',
                      'flex-row',
                      'width-full',
                    ].join(' ')}
                  >
                    <h2 className="margin-0 text-base-dark">
                      {panelContent[objectPanel].title}
                    </h2>
                    <div
                      className={[
                        'display-flex',
                        'flex-gap-xs',
                        'flex-row',
                        'margin-left-auto',
                      ].join(' ')}
                    >
                      {/*<ButtonSharePage id="preview" />*/}
                      <Button
                        aria-label={`Collapse the ${objectPanel} panel.`}
                        data-testid={`nac-object-viewer--${objectPanel}-panel--close-btn`}
                        iconName="close"
                        iconPosition="right"
                        iconSize="xs"
                        onClick={() => {
                          if (
                            mode === 'transcription' &&
                            params.objectPanel === 'combo'
                          ) {
                            updateParams({
                              objectPanel: 'extracted',
                            })
                          }
                          if (
                            mode === 'extracted' &&
                            params.objectPanel === 'combo'
                          ) {
                            updateParams({
                              objectPanel: 'transcription',
                            })
                          }
                          if (!mode) {
                            removeParams(['objectPanel'])
                          }
                        }}
                        reduced
                        size="2xs"
                        textOnly
                        theme="primary"
                        thin
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                  {objectPanel == 'transcription' && (
                    <div
                      className={[
                        'font-sans-3xs',
                        'text-base-dark',
                        'text-left',
                      ].join(' ')}
                    >
                      {currentActiveTranscription ? (
                        <>
                          {loggedInUser?.userId &&
                            objectPanel === 'transcription' &&
                            currentTranscriptionIsLocked && (
                              <Tooltip
                                content={
                                  loggedInUserIsEditing
                                    ? 'This transcription will remain locked while you are editing it.'
                                    : 'This transcription is locked because it is being edited by another user.'
                                }
                                position="bottomLeft"
                              >
                                <Pill
                                  filled
                                  iconName="locked"
                                  iconSize="2xs"
                                  reduced
                                  size="3xs"
                                  theme="warning-light"
                                  thin
                                >
                                  Locked for editing
                                </Pill>
                              </Tooltip>
                            )}

                          {loggedInUserIsEditing && currentTranscription ? (
                            <>Last saved at {lastSaved}</>
                          ) : (
                            !loading && (
                              <>
                                {currentTranscription && (
                                  <>
                                    Updated by{' '}
                                    {currentTranscription?.madeByAdministrator ===
                                    1 ? (
                                      <span>
                                        <strong>
                                          {currentTranscription.userName}{' '}
                                        </strong>
                                        <StaffPill />
                                      </span>
                                    ) : (
                                      <a
                                        href={`/accounts/${currentTranscription.userName}`}
                                        className="text-bold"
                                      >
                                        <strong>
                                          {currentTranscription.userName}{' '}
                                        </strong>
                                        {currentTranscription.naraStaff ===
                                          1 && <StaffPill />}
                                      </a>
                                    )}{' '}
                                    (
                                    {timeSince(
                                      currentTranscription?.updatedAt ||
                                        currentTranscription?.createdAt
                                    )}
                                    )
                                  </>
                                )}
                              </>
                            )
                          )}
                        </>
                      ) : (
                        loggedInUser?.userId &&
                        objectPanel === 'transcription' &&
                        currentTranscriptionIsLocked && (
                          <Tooltip
                            content="This transcription is locked because it is currently being edited by another user."
                            position="bottomLeft"
                          >
                            <Pill
                              filled
                              iconName="locked"
                              iconSize="2xs"
                              reduced
                              size="3xs"
                              theme="warning-light"
                            >
                              Locked
                            </Pill>
                          </Tooltip>
                        )
                      )}
                    </div>
                  )}
                </div>
                {objectTagsLoading || loading ? (
                  <ObjectContributionsLoader type={objectPanel} />
                ) : (
                  <>
                    {(activeTranscriptions &&
                      activeTranscriptions.length !== 0) ||
                    isEditing ||
                    (objectTags && objectTags.length !== 0) ||
                    (comments && comments.length !== 0) ||
                    objectPanel === 'extracted' ||
                    objectPanel === 'aiextracted' ? (
                      <>
                        {/**
                         * Contributions
                         */}
                        <div
                          className={[
                            'display-flex',
                            'flex-column',
                            'flex-gap',
                            'grid-col',
                            'height-full',
                            'margin-x-auto',
                            'minh-0',
                            'overflow-y-auto',
                            objectPanel == 'transcription'
                              ? ''
                              : objectPanel == 'comment'
                              ? 'padding-y-1'
                              : 'padding-x-2 padding-y-1',

                            'width-full',
                          ].join(' ')}
                        >
                          {objectPanel == 'transcription' && (
                            <Transcription isEditing={isEditing} />
                          )}
                          {objectPanel == 'tag' && objectTags && (
                            <TagGroup tags={objectTags} />
                          )}
                          {objectPanel == 'comment' && comments && (
                            <CommentGroup comments={comments} />
                          )}
                          {objectPanel === 'aiextracted' && (
                            <div
                              className={[
                                'display-flex',
                                'flex-column',
                                'grid-col',
                                'height-full',
                                'margin-x-auto',
                                'overflow-y-auto',
                                'padding-x-2',
                                'padding-y-1',
                                'width-full',
                              ].join(' ')}
                            >
                              <AIChat
                                s3ImageUrl={filteredObjects[0]?.objectUrl}
                                digitalObjectInfo={filteredObjects[0]}
                              />
                            </div>
                          )}
                          {objectPanel == 'extracted' && (
                            <div>
                              <div>
                                {!otherExtractedText &&
                                averageConfidenceScore &&
                                averageConfidenceScore <= 60 ? (
                                  <Alert type="warning">
                                    The item does have Optical Character
                                    Recognition (OCR), but the confidence level
                                    is low due to handwriting legibility ,
                                    typeface clarity, or image clarity. Please
                                    consider transcribing this page.
                                  </Alert>
                                ) : (
                                  ''
                                )}
                                {!otherExtractedText &&
                                !averageConfidenceScore ? (
                                  <Alert type="warning">
                                    Confidence index is not available.
                                  </Alert>
                                ) : (
                                  ''
                                )}
                              </div>
                              {otherExtractedText && (
                                <>
                                  <div className={['extracted-text-sub-title']}>
                                    Contributed by{' '}
                                    {
                                      otherExtractedText.contributor
                                        .partnerFullName
                                    }
                                    {otherExtractedText.contributor
                                      .naraStaff ? (
                                      <StaticPill label={'NARA Partner'} />
                                    ) : (
                                      ''
                                    )}
                                    {otherExtractedText.aiMachineGenerated ? (
                                      <StaticPill
                                        label={'AI / Machine-Generated'}
                                      />
                                    ) : (
                                      ''
                                    )}
                                    (
                                    {timeSince(
                                      otherExtractedText.updatedAt ||
                                        otherExtractedText.createdAt
                                    )}
                                    )
                                  </div>
                                  {otherExtractedText.contribution}
                                </>
                              )}
                              {!otherExtractedText && extractedText && (
                                <>
                                  <div
                                    className={['display-flex', 'flex-column']}
                                  >
                                    <h4>
                                      Optical Character Recognition (OCR)
                                      Extracted Text
                                    </h4>
                                    <div
                                      className={['extracted-text-sub-title']}
                                    >
                                      Auto-generated by the National Archives
                                      Catalog
                                    </div>
                                  </div>
                                  {extractedText}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      !loggedInUserIsEditing && (
                        <>
                          {/**
                           * Empty Contribution Message
                           */}
                          <div
                            className={[
                              'display-flex',
                              'flex-align-center',
                              'flex-justify-center',
                              'flex-column',
                              'flex-gap',
                              'grid-col',
                              'height-full',
                              'margin-x-auto',
                              'maxw-card-lg',
                              'overflow-y-auto',
                              'padding-x-2',
                              'padding-y-1',
                              'text-base-dark',
                              'text-center',
                            ].join(' ')}
                          >
                            <span className="font-sans-md">
                              {panelContent[objectPanel].emptyText}
                            </span>
                            <div
                              className={[
                                'nac-illustration',
                                `nac-illustration--${objectPanel}`,
                                'height-full',
                                'maxh-15',
                                'minh-10',
                                'opacity-20',
                                'width-full',
                              ].join(' ')}
                            ></div>
                            <h3 className="text-bold text-base-dark text-italic">
                              Did you know?
                            </h3>
                            <p className="font-sans-xs">
                              {panelContent[objectPanel].note}
                            </p>
                          </div>
                        </>
                      )
                    )}
                  </>
                )}
                {/**
                 * Contribution Panel Footer
                 */}

                {objectPanel != 'extracted' && objectPanel != 'aiextracted' && (
                  <div
                    className={[
                      'border-base-light',
                      'border-top-1px',
                      'padding-x-2',
                      'padding-y-1',
                      'width-full',
                    ].join(' ')}
                  >
                    {objectPanel === 'tag' ? (
                      <TagInput tags={objectTags} objectId={objectId} />
                    ) : objectPanel === 'comment' ? (
                      <CommentInput comments={comments} objectId={objectId} />
                    ) : (
                      <TranscriptionActions
                        onloggedInUserIsEditing={() => {
                          setIsEditing(true)
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Rnd>
        )}
      </div>
    ) : (
      ''
    )
  }
)

ContributionPanel.defaultProps = {
  contributions: [],
  screenSize: 'desktop',
}

ContributionPanel.propTypes = {
  averageConfidenceScore: PropTypes.number,
  mode: PropTypes.string,
  filteredObjects: PropTypes.array,
  contributions: PropTypes.array.isRequired,
  screenSize: PropTypes.oneOf(['mobile', 'tablet', 'tablet-lg', 'desktop']),
}

ContributionPanel.displayName = 'ContributionPanel'

export default ContributionPanel
